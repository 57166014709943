import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { combineLatest } from "rxjs/internal/observable/combineLatest";
import { map } from "rxjs/internal/operators/map";
import { switchMap, take } from "rxjs/operators";
import { PAGINACAO_PAGE_SIZE } from "src/app/shared/constantes";
import { Page } from "src/app/shared/interfaces/page.type";
import { IPagination } from "src/app/shared/interfaces/pagination.type";
import { environment } from "src/environments/environment";

export abstract class CrudService<T> {

    protected readonly apiUrl = `${this.baseUrl}/${this.entityName}`;

//    protected readonly apiUrl = `${this.baseUrl}`;
    constructor(
        protected readonly http: HttpClient,
        protected readonly entityName: string,
        protected readonly baseUrl: string = environment.baseUrl
    ) {}

    consultaPaginada(pageChange:Observable<IPagination>, search?: Observable<{ [key: string]: string }>): Observable<Page<T[]>> {
        return this.consultaPaginadaPath(pageChange, search, 'consulta');
    }

    consultaPaginadaPath(pageChange:Observable<IPagination>, search: Observable<{ [key: string]: string }>, path: String): Observable<Page<T[]>> {
        return combineLatest([
            pageChange, search
            ])
        .pipe(
          map(([pageChange, search]) => {    
            let param = search;
                param['page'] = pageChange.pageIndex? `${pageChange.pageIndex-1}` : `${-1}`;
                param['size'] = pageChange.pageSize? `${pageChange.pageSize}` : `${PAGINACAO_PAGE_SIZE}`;
                return param;
              }),
          switchMap(param => this.consultar(param, path)));
    }

    consultar(search: { [key: string]: string }, path: String): Observable<Page<T[]>> {
        const params = new HttpParams({ fromObject: search });
        return this.http.get<Page<T[]>>(`${this.apiUrl}/${path}`, { params });
    }

    listar(query?: { [key: string]: string }): Observable<T[]> {
        const params = new HttpParams({ fromObject: query });
        return this.http.get<T[]>(this.apiUrl, { params });
    }

    criar(body: T): Observable<T> {
        return this.http.post<T>(this.apiUrl, body)
        .pipe(take(1));
    }

    atualizar(id: number, body: T): Observable<T> {
        return this.http.put<T>(`${this.apiUrl}/${id}`, body)
        .pipe(take(1));
    }

    buscar(id: number): Observable<T> {
        return this.http.get<T>(`${this.apiUrl}/${id}`)
        .pipe(take(1));
    }

    deletar(id: number): Observable<T> {
        return this.http.delete<T>(`${this.apiUrl}/${id}`)
        .pipe(take(1));
    }
}